Prism.languages.lolcode = {
	'comment': [
		/\bOBTW\s[\s\S]*?\sTLDR\b/,
		/\bBTW.+/
	],
	'string': {
		pattern: /"(?::.|[^":])*"/,
		inside: {
			'variable': /:\{[^}]+\}/,
			'symbol': [
				/:\([a-f\d]+\)/i,
				/:\[[^\]]+\]/,
				/:[)>o":]/
			]
		},
		greedy: true
	},
	'number': /(?:\B-)?(?:\b\d+(?:\.\d*)?|\B\.\d+)/,
	'symbol': {
		pattern: /(^|\s)(?:A )?(?:BUKKIT|NOOB|NUMBAR|NUMBR|TROOF|YARN)(?=\s|,|$)/,
		lookbehind: true,
		inside: {
			'keyword': /A(?=\s)/
		}
	},
	'label': {
		pattern: /((?:^|\s)(?:IM IN YR|IM OUTTA YR) )[a-zA-Z]\w*/,
		lookbehind: true,
		alias: 'string'
	},
	'function': {
		pattern: /((?:^|\s)(?:HOW IZ I|I IZ|IZ) )[a-zA-Z]\w*/,
		lookbehind: true
	},
	'keyword': [
		{
			pattern: /(^|\s)(?:AN|FOUND YR|GIMMEH|GTFO|HAI|HAS A|HOW IZ I|I HAS A|I IZ|IF U SAY SO|IM IN YR|IM OUTTA YR|IS NOW(?: A)?|ITZ(?: A)?|IZ|KTHX|KTHXBYE|LIEK(?: A)?|MAEK|MEBBE|MKAY|NERFIN|NO WAI|O HAI IM|O RLY\?|OIC|OMG|OMGWTF|R|SMOOSH|SRS|TIL|UPPIN|VISIBLE|WILE|WTF\?|YA RLY|YR)(?=\s|,|$)/,
			lookbehind: true
		},
		/'Z(?=\s|,|$)/
	],
	'boolean': {
		pattern: /(^|\s)(?:FAIL|WIN)(?=\s|,|$)/,
		lookbehind: true
	},
	'variable': {
		pattern: /(^|\s)IT(?=\s|,|$)/,
		lookbehind: true
	},
	'operator': {
		pattern: /(^|\s)(?:NOT|BOTH SAEM|DIFFRINT|(?:ALL|ANY|BIGGR|BOTH|DIFF|EITHER|MOD|PRODUKT|QUOSHUNT|SMALLR|SUM|WON) OF)(?=\s|,|$)/,
		lookbehind: true
	},
	'punctuation': /\.{3}|…|,|!/
};
